import "./src/global.css";

export const onClientEntry = () => {
  const setVh = () => {
    const vh = window.innerHeight * 0.01;
    document.body.style.setProperty("--vh", `${vh}px`);
  };

  window.addEventListener("load", setVh);
  window.addEventListener("resize", setVh);
};
